import Layout from "../components/layout"
import React from "react"
import { graphql } from "gatsby"

const PortfolioPage = ({location, data}) => {

  return (
    <Layout location={location} title="Projects">
      <h1>Projects</h1>
      <p>Coming soon.</p>
      {/*<p>Here are some of the things I've built.</p>*/}
    </Layout>
  );
}

export default PortfolioPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
